import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import { Link } from "gatsby";
import SEO from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Refs & DOM" mdxType="SEO" />
    <h1>{`Мини-воркшоп Refs & DOM`}</h1>
    <p><a parentName="p" {...{
        "href": "https://codesandbox.io/s/refs-mini-workshop-kyelw?fontsize=14&hidenavigation=1&theme=dark"
      }}><img parentName="a" {...{
          "src": "https://codesandbox.io/static/img/play-codesandbox.svg",
          "alt": "Edit Refs mini-workshop"
        }}></img></a></p>
    <h2>{`Как работать с репозиторием`}</h2>
    <p>{`Воркшоп состоит из трех задач, которые лучше решать по порядку.`}</p>
    <p>{`В браузере `}<inlineCode parentName="p">{`CodeSandbox`}</inlineCode>{` на странице задания в левом окне компонент, который
нужно исправить, в правом - как должен работать.`}</p>
    <p>{`Для решения заданий нужно переходить в папку `}<inlineCode parentName="p">{`exercises`}</inlineCode>{` и выбирать соответствующий
заданию файл. В самом верху файла в блоке комментария находятся описание задания и
зашифрованные подсказки.`}</p>
    <p>{`Чтобы расшифровать подсказку, нужно воспользоваться в консоли `}<inlineCode parentName="p">{`CodeSandbox`}</inlineCode>{`
функцией `}<inlineCode parentName="p">{`decodeHint`}</inlineCode>{`, которая добавлена в глобальный объект `}<inlineCode parentName="p">{`window`}</inlineCode>{` для
удобства. Внизу, внутри компонента `}<inlineCode parentName="p">{`Usage`}</inlineCode>{`, пример того, как будет использоваться
целевой компонент. Компонент `}<inlineCode parentName="p">{`Usage`}</inlineCode>{` редактировать не нужно.`}</p>
    <p>{`В `}<inlineCode parentName="p">{`CodeSandbox`}</inlineCode>{` во вкладке `}<inlineCode parentName="p">{`Tests`}</inlineCode>{` можно увидеть результат выполнения тестов
по всем заданиям сразу. Задание считается выполненным после прохождения всех
относящихся к нему тестов без ошибок.`}</p>
    <p>{`Итоговые решения из правого окна каждого задания лежат в папке `}<inlineCode parentName="p">{`exercises-final`}</inlineCode>{`.`}</p>
    <Link to="/" mdxType="Link">Вернуться на главную</Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      